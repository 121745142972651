export const MyCompany = "Nexic";
export const CompanyDescription =
  "Nexic IT offers cutting-edge solutions tailored to your business needs. We specialize in streamlining technology infrastructure, enhancing cybersecurity, and integrating advanced data pipelines. Our expert team focuses on transforming your tech landscape to drive efficiency and align with your strategic goals, ensuring you stay ahead in the ever-evolving digital world.";
export const FacebookLink = "";
export const TwitterLink = "";
export const LinkedInLink = "";
export const CompanyEmail = "hr@nexicit.net";
export const MailTo = "mailto:" + CompanyEmail;
export const CompanyPOInfo = "7301 N. State Highway 161, Suite 310";
export const CompanyLocation = "Irving, TX 75039";
export const CompanyContactDetail = "(972)-626-0302";
export const isCareers = true; //Set the value according to no vacancy
export const NoVanancy = (
  <>
    Thank you for your interest. Unfortunately, at this time, we don't have any
    opportunities at Nexic. However, please check back regularly to view any new
    positions that become available. We always like to hear from super-talented
    individuals.
    <br /> If that's you, please {" "}
    <a
      className="m-1"
      href="mailto:hr@nexic.com"
      style={{ display: "contents", textDecoration: "none" }}
    >
    email 
    </a>
    {" "} us your resume, with a cover letter.
  </>
);

//API Key
export const PublicKey = "";
export const ServiceId = "";
export const TemplateId = "";
